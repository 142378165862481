import styled from 'styled-components';

export const Footer = styled.footer`
    font-family: 'Source', sans-serif;
    color: #000;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    min-height: 70vh;
    overflow: hidden;

    @media (max-width: 414px) {
        min-height: 35vh;
    }
    @media (max-width: 800px) {
        min-height: 35vh;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 114%;
        bottom: -41%;
        position: absolute !important;

        @media (max-width: 414px) {
            height: 56vh;
            bottom: -65%;
        }
        @media (max-width: 800px) {
            height: 56vh;
            bottom: -68%;
        }
        @media (min-width: 1200px) {
            height: 144%;
            bottom: -55%;
        }
    }

    .medium, .mobile, .large {
        display: none;
    }


    a {
        color: #000;
        font-weight: 700;
    }

    .small {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    @media only screen and (min-width: 808px) and (max-width: 1400px) {
        .medium {
        display: block;
        }
    }
        
    @media only screen and (max-width: 808px) {
        .mobile {
        display: block; 
        }
    }

    @media only screen and (min-width: 1400px) {
        .large {
        display: block; 
        }
    }

    .footerText {
        position: absolute;
        width: 80%;
        text-align: center;
        bottom: 3.5rem;
        left: 50%;
        transform: translate(-50%);
        color: #F3F7F0;
        @media only screen and (max-width: 808px) {
        bottom: 2rem;
        }
    }
`;
