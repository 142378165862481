import React from 'react';
import PropTypes from 'prop-types';

import MainNav from '../MainNav';
import Logo from '../Logo';

import * as Styled from './styles';

const Nav = () => (
  <Styled.Nav>
      <Logo />
      <MainNav />
  </Styled.Nav>
);

Nav.defaultProps = {
  siteTitle: PropTypes.any
};

Nav.propTypes = {
  siteTitle: PropTypes.any
};

export default Nav;
