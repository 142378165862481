import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, lang, meta, title, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      defaultTitle="Ruth Dillon-Mansfield's Blog & Portfolio"
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: `Ruth Dillon-Mansfield`
        },
        {
          name: `og:image`,
          content: image ? image : `https://res.cloudinary.com/ruth-ng/image/upload/v1658242652/ruth-dillon-mansfield-blog-portfolio.png`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: image ? image : `https://res.cloudinary.com/ruth-ng/image/upload/v1658242652/ruth-dillon-mansfield-blog-portfolio.png`
        },
        {
          name: `twitter:image:alt`,
          content: `Ruth Dillon-Mansfield | Full-Stack Growth Marketer`
        },
        {
          name: `twitter:site`,
          content: "@ruthdillonmans"
        },
        {
          name: `twitter:creator`,
          content: "@ruthdillonmans"
        },
          // <meta property="og:url" content={`"https://ruth-ng.co.uk/pages/${page}/`} />
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.any,
  title: PropTypes.string.isRequired
};

export default SEO;
