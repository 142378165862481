import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Styled from './styles';

const Footer = () => {
  const { footerimage } = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    footerimage: file(relativePath: { eq: "landscape-segment-dark.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);
  return (
    <Styled.Footer>
      <Img fluid={footerimage.childImageSharp.fluid} alt='Ruth Dillon-Mansfield mountain illustration' />
      <div className="footerText">
        <p className="mobile">
          ruth.dillonmansfield@gmail.com
        </p>
        <p className="mobile">
          She/her
        </p>
        <p className="medium large">
        Ruth Dillon-Mansfield (n&#233;e Ng) | She/her
        </p>
        <p className="small medium large">Built with Gatsby, React, GraphQL and Styled Components</p>
      </div>
    </Styled.Footer>
  );
}

export default Footer;