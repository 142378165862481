import React from 'react';
import PropTypes from 'prop-types';

import Nav from './Nav';

import * as Styled from './styles';

const Header = () => (
  <Styled.Header>
    <Nav />
  </Styled.Header>
);

Header.defaultProps = {
  siteTitle: PropTypes.string.isRequired
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

export default Header;
