import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

export const MainNav = styled.nav`
    z-index: 2;
    width: 60%;
    font-family: Kosugi;
    margin-top: 3.9rem;
    display: flex;
    justify-content: right;
    align-items: center;
    position: absolute;
    right: 2rem;
    top: 0;

    a {
        color: black;
        text-decoration: none;
    }
`;

export const MainNavItem = motion.custom(styled(Link)`
    @media (max-width: 800px) {
        display: none;
    }
    margin: 0 20px 0 20px;

    &.active {
        color: #4CAF50;
    }
`);

export const MobileNav = styled.nav`
    transition: all .4s ease-out .1s;
    z-index: 3;
    width: 100vw;
    height: 103vh;
    background-image: linear-gradient(335deg, rgba(255,255,255,.4) 0%, rgba(255,255,255,1) 52%);
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100vw, 0);
    opacity: 100%;
    font-size: 20px;
    font-family: Kosugi;
    display: flex;
    flex-direction: column;
    padding: 9rem 2rem 0 0;

    @media (max-width: 800px) {
        &.open {
            transform: translate(0, 0);
            opacity: 1;
        }
        &.closed {
            transform: translate(-100vw, 0);
            opacity: 0;
        }
    }
`;

export const MobileNavItem = motion.custom(styled(Link)`
    @media (min-width: 800px) {
        display: none;
    }
    padding: 1rem;
    color: black;
    text-decoration: none;
    text-align: right;

    &.active {
        color: #4CAF50;
    }

    &::before {
        transition: all .2s ease 0;
        position: absolute;
        bottom: -1px;
        width: 100%;
        background-color: red;
        height: 3px;
        left: 0;
        visibility: hidden;
        content: "";
        transform: scaleX(0);

        &:hover {
            transform: scaleX(100px);
        }
    }
`);

export const ToogleMainNav = styled.button`
    position: absolute;
    z-index: 100;
    right: 2rem;
    top: 2.3rem;
    width: 5rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    outline: none !important;
    border: none !important;
    background-color: transparent;
    background-image: none;

    @media (min-width: 800px) {
        display: none;
    }

    span {
        transition: all 0.2s ease 0s;
        background-color: #221e1f;
        display: inline-block;
        width: 4rem;
        height: 3px;
    }

    span:first-child, span:last-child {
        margin-bottom: 0.5rem;
        transform: none;
        height: 3px;
    }
    span:nth-child(2) {
        background-color: #797b91;
        display: inline-block;
        margin-bottom: 0.5rem;
        width: 5rem;
        height: 3px;
        transform: none;
    }

    &.open {
        span:first-child {
            margin-bottom: -5px;
            transform: rotate(45deg);
        }
        span:nth-child(2) {
            transform: translate(20rem);
        }
        span:last-child {
            margin-top: -6px;
            transform: rotate(-45deg);
        }
    }
`;

export const NavLogic = styled.div`
`;

export const NavItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    &:hover span {
        opacity: 100;
        transform: translate(25%, 5px);
        width: 67%;
    }
    
    span {
        height: 1.5px;
        background-color: #797b91;
        transform: translate(100px, 5px);
        opacity: 0;
        transition: all .15s ease-in;
    }
`;