import styled from 'styled-components';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
    z-index:1;
    width: 80px;
    position: absolute;
    left: 2rem;
    top: 1rem;

    @media (max-width: 800px) {
        left: 0;
        display: none;
    }
`;

export const Text = styled.h1`
`;

export const Image = styled.figure`
`;
