import styled from 'styled-components';

export const Nav = styled.header`
    position: fixed;
    width: 100%;
    height: 6.7rem;
    padding-top: 1.1rem;
    margin-top: -1rem;
    font-size: 20px;
    font-family: Kosugi;
    z-index: 3000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);

    @media (min-width: 800px) {
        background-color: rgba(255, 255, 255, 0.85);
        height: 9rem;
    }
`;